import { useState } from "react"
import { productDeleteConfirm } from "../../components/delete_modal/delete_modal"
import NoData from "../../components/noData/NoData"
import {
	DotsThreeVertical,
	PencilSimple,
	Trash,
	UserMinus,
} from "@phosphor-icons/react"
import moment from "moment/moment"

function GoodsList({
	data,
	deleteGood,
	editGood,
	showDropdown,
	setshowDropdown,
	userInfo,
	darkMode,
}) {
	const [loc, setLoc] = useState(true)

	const handleClick = (e, id) => {
		showDropdown === id ? setshowDropdown("") : setshowDropdown(id)
		e.stopPropagation()
		setLoc(window.innerHeight - e.clientY > 110 ? false : true)
	}

	return data?.length ? (
		<div className={`card-wrapper goods grid ${darkMode ? "dark" : null}`}>
			{data.map((item, idx) => {
				return (
					<div key={idx} className="card-item goods">
						<div className="card-item-top">
							<div>
								<h3>{item?.goods_name}</h3>
							</div>
							{userInfo === 1 ? (
								<div>
									<div className="card-item-edit-holder">
										<button
											type="button"
											onClick={(e) => handleClick(e, item?.goods_id)}
										>
											<DotsThreeVertical size={24} />
										</button>
										<div
											className={`card-item-edit-wrapper ${
												showDropdown === item?.goods_id || "hidden"
											} ${loc && "top"}`}
										>
											<button
												type="button"
												className="card-item-edit-item"
												onClick={(e) => {
													e.stopPropagation()
													setshowDropdown("")
													editGood(item?.goods_id)
												}}
											>
												Tahrirlash <PencilSimple size={20} />
											</button>
											<button
												type="button"
												className="card-item-edit-item"
												onClick={(e) =>
													productDeleteConfirm(
														e,
														<>
															<span>{item?.goods_name}</span> kategoriyani
														</>,
														deleteGood,
														item?.goods_id,
														darkMode
													)
												}
											>
												O'chirish <Trash size={20} />
											</button>
										</div>
									</div>
								</div>
							) : null}
						</div>

						<div className="card-item-bottom goods">
							<div className="card-item-bottom__left">
								<h3>Kod: {item?.goods_code}</h3>
								<h3>{item?.deliver_name}</h3>
								<h4>{moment(item?.goods_createdat).format("YYYY/MM/DD")}</h4>
							</div>

							<div className="card-item__image">
								<img
									src={item?.img_url}
									alt=""
									onClick={() => window.open(item?.img_url)}
								/>
							</div>
						</div>
					</div>
				)
			})}
		</div>
	) : (
		<NoData />
	)
}

export default GoodsList
